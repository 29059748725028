import Nubitt, { getApiURL } from "./Base";

export default class Ads extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Ads : Admin - Nubitt Dashboard";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/ads/all", {
            headers: {
                //'Authorization': 'Bearer ' + global.current_user.token,
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.files
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    toggleEnabled = function(e, item) {

        item.file_active = (e.target.checked) ? 1 : 0;
        let form = new FormData();
        form.append('enabled', item.file_active);
        fetch(getApiURL() + "/ads/toggle_enable/" + item.id, {
            method: 'POST',
            headers: {
                //'Authorization': 'Bearer ' + global.current_user.token,
            },
            body: form
        })
    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items && items.length > 0) {
            return (
                <div>
                    <h2>Ads</h2>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Enabled?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={'ad_' + item.id}>
                                    <td>
                                        {item.file_name}<br />
                                        <small>{item.file_url}</small>
                                    </td>
                                    <td>{item.date_added}</td>
                                    <td><input onChange={event => this.toggleEnabled(event, item)} defaultChecked={item.file_active} className="show_file" type="checkbox" name={'file_enabled_' + item.id} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        
            );
        } else if (items.length === 0) {
            return (
                <div></div>
            );
        }

    }

}