import Nubitt, { getApiURL, getCurrentUserToken, getProducerURL } from './Base';

export default class Apps extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false,
            fields: {
                app_name: '',
                app_key: '',
                login_user: '',
                login_pass: '',
                email: '',
                username: ''
            }
        };
        this.handleOnChange = this.handleOnChange.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Apps : Admin - Nubitt Dashboard";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/apps/all", {
            headers: {
                'Authorization': 'Bearer ' + getCurrentUserToken(),
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.apps
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    toggleAppAvailability = function(e, item) {

        item.app_enabled = (e.target.checked) ? 1 : 0;
        let form = new FormData();
        form.append('app_enabled', item.app_enabled);
        fetch(getApiURL() + "/apps/toggle_availability/" + item.id, {
            method: 'POST',
            headers: {
                //'Authorization': 'Bearer ' + getCurrentUserToken(),
            },
            body: form
        })
    }

    handleOnChange(e) {
        const stateData = this.state;
        stateData.fields[e.target.name] = e.target.value
        this.setState({stateData});
    }

    clearAllFields() {
        for (let field in this.state.fields) {
            this.state.fields[field] = '';
        }  
    }

    handleOnSubmit(e) {
        e.preventDefault();
        console.log(e);
        console.log(this.state.fields);

        fetch(getApiURL() + "/apps/create", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fields: this.state.fields
            })
        })
        .then(
            (result) => {
                console.log(result);
                this.clearAllFields();
                this.loadData();
            }
        )

    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items && items.length > 0) {
            return (
                <div>
                    
                    <h2>Add New App</h2>
                    <form id="add_producer" onSubmit={this.handleOnSubmit}>
                        <div>
                            <p>
                                <span style={{display: 'inline-block', width: '100px'}}>App Key</span>
                                <input
                                    onChange={this.handleOnChange}
                                    size="5" 
                                    type="text" 
                                    name="app_name" 
                                    style={{marginLeft: -2 + 'px'}}
                                    value={this.state.fields.app_name} />
                            </p>
                            <p>
                                <span style={{display: 'inline-block', width: '100px'}}>App Name</span>
                                <input 
                                    onChange={this.handleOnChange}
                                    size="50" 
                                    type="text" 
                                    name="app_key" 
                                    style={{marginLeft: -2 + 'px'}}
                                    value={this.state.fields.app_key} />
                            </p>
                            <p>
                                <span style={{display: 'inline-block', width: '100px'}}>Email</span>
                                <input 
                                    onChange={this.handleOnChange}
                                    size="30" 
                                    type="text" 
                                    name="email" 
                                    style={{marginLeft: -2  + 'px'}}
                                    value={this.state.fields.email} />
                            </p>
                            <p>
                                <span style={{display: 'inline-block', width: '100px'}}>Login User</span>
                                <input 
                                    onChange={this.handleOnChange}
                                    size="15" 
                                    type="text" 
                                    name="login_user" 
                                    style={{marginLeft: -2 + 'px'}}
                                    value={this.state.fields.login_user} />
                            </p>
                            <p>
                                <span style={{display: 'inline-block', width: '100px'}}>Login Pass</span>
                                <input 
                                    onChange={this.handleOnChange}
                                    size="15" 
                                    type="text" 
                                    name="login_pass" 
                                    style={{marginLeft: -2 + 'px'}}
                                    value={this.state.fields.login_pass} />
                            </p>
                            <p>
                                <span style={{display: 'inline-block', width: '100px'}}>Twitter/X @</span>
                                <input 
                                    onChange={this.handleOnChange}
                                    size="20" 
                                    type="text" 
                                    name="username" 
                                    style={{marginLeft: -2  + 'px'}}
                                    value={this.state.fields.username} />
                            </p>
                            <input onClick={this.createProducer} type="submit" name="create-account" value="Create Producer Account" />
                        </div>
                    </form>

                    <h2>Existing Apps</h2>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>App ID</th>
                                <th>App Name</th>
                                <th>Login User</th>
                                <th>&nbsp;</th>
                                <th>App Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={'app_' + item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.app_name}</td>
                                    <td>{item.app_user_id}</td>
                                    <td><small><a target="_blank" rel="noreferrer" href={getProducerURL(item.app_login_uri)}><strong>Login As User</strong></a></small></td>
                                    <td><input onChange={event => this.toggleAppAvailability(event, item)} defaultChecked={item.app_enabled} className="show_app" type="checkbox" name={'app_enabled_' + item.id} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        
            );
        } else if (items.length === 0) {
            return (
                <div></div>
            );
        }

    }

}