import Nubitt, { getApiURL } from './Base';

export default class Videos extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Videos : Admin - Nubitt Dashboard";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/videos/all", {
            headers: {
                //'Authorization': 'Bearer ' + global.current_user.token,
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.videos
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items && items.length > 0) {
            return (
                <div>
                    <h2>Videos</h2>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Video ID</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Views</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={'video_' + item.video_id}>
                                    <td>{item.video_id}</td>
                                    <td>{item.video_name} <small>({item.video_length} seconds)</small><br /></td>
                                    <td><small>{item.date_added}</small></td>
                                    <td>{item.view_count}</td>
                                </tr> 
                            ))}
                        </tbody>
                    </table>
                </div>
        
            );
        } else if (items.length === 0) {
            return (
                <div></div>
            );
        }

    }

}