//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Apps from './Apps';
import Files from './Files';
import Videos from './Videos';
import Header from './Header';
import Footer from './Footer';
import Login from './Login';
import Ads from './Ads';

function App() {
  return (
    <div>
      <Header />
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/files" element={<Files />} />
          <Route path="/videos" element={<Videos />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();