import { Component } from 'react'
export default class Nubitt extends Component {

    isLoggedIn() {
        let userData = localStorage.getItem("user_data");
        if (userData) {
            let user = JSON.parse(userData);
            if (user.username === 'nubitt' && user.auth_response) {
                return true;
            }
        }
        return false;
    }

} 

export function getCurrentUserToken() {
    return '';
}

export function getProducerURL(slug) {
    if (window.document.location.href.indexOf('admin.nubitt.com') !== -1) {
        return 'https://producer.nubitt.com/' + slug;
    } else {
        return 'http://localhost:3002/' + slug;
    }
}

export function getApiURL() {
    if (window.document.location.href.indexOf('admin.nubitt.com') !== -1) {
        return 'https://api.nubitt.com/api/admin';
    } else {
        return 'http://0.0.0.0:3000/api/admin';
    }
}