import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class Home extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {}
    componentWillUnmount() {}

    render() {

        return (
            <div id="home_box">
                <h2>Apps</h2>
                <p>
                    <Link to="/apps">Manage Apps</Link>
                </p>
                <p>
                    <Link to="/files">Manage Files</Link>
                </p>
                <p>
                    <Link to="/ads">Manage Ads</Link>
                </p>
                
                <h2>Videos</h2>
                <p>
                    <Link to="/videos">Manage Videos</Link>
                </p>
            </div>
          );

    }

}