import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (window.location.pathname !== "/") {
  // check for logged in user data
  let userData = localStorage.getItem("user_data");
  if (userData) {
    let user = JSON.parse(userData);
    if (user.username === 'nubitt' && user.auth_response) {
      console.log('User is authorized to proceed');
      console.log(userData);
    } else {
      document.location = "/";
    }
  } else {
    document.location = "/";
  }
}

console.log(window.location.pathname);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
