import Nubitt, { getApiURL } from "./Base";

export default class Files extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Files : Admin - Nubitt Dashboard";
        this.loadData();
    }

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this file?");
        if (confirmed) {

            fetch(getApiURL() + "/file/" + id, {
                method: 'DELETE',
                //headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    window.location.reload()
                }
            )

        }

    }

    toggleEnabled = function(e, item) {

        item.file_active = (e.target.checked) ? 1 : 0;
        let form = new FormData();
        form.append('enabled', item.file_active);
        fetch(getApiURL() + "/files/toggle_enable/" + item.id, {
            method: 'POST',
            headers: {
                //'Authorization': 'Bearer ' + global.current_user.token,
            },
            body: form
        })
    }

    loadData() {

        fetch(getApiURL() + "/files/all", {
            headers: {
                //'Authorization': 'Bearer ' + global.current_user.token,
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.files
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items && items.length > 0) {
            return (
                <div>
                    <h2>Files</h2>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>File Name</th>
                                <th>File Date</th>
                                <th>Enabled?</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={'file_' + item.id}>
                                    <td>{item.id}</td>
                                    <td>
                                        {item.file_name}<br />
                                        <small>{item.file_url}</small>
                                    </td>
                                    <td>{item.date_added}</td>
                                    <td><input onChange={event => this.toggleEnabled(event, item)} defaultChecked={item.file_active} className="show_file" type="checkbox" name={'file_enabled_' + item.id} /></td>
                                    <td><a href="/" onClick={(e) => this.deleteItem(e, item.id)}>Delete</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        
            );
        } else if (items.length === 0) {
            return (
                <div></div>
            );
        }

    }

}