import React, { Component } from 'react'

export default class Header extends Component {

    constructor(props) {
		
        super(props);
		console.log(props);
		
        this.state = {
			error: null
		};

		this.doLogout = this.doLogout.bind(this);

	}

    doLogout = function(e) {
        e.preventDefault();
        localStorage.clear();
        document.location = "/";
    }

    render() {
        return (
            <div id="header">
                <strong><a href="/home">Nubbit Admin</a></strong>
                <small onClick={this.doLogout} className="sign_out_link">(<a href="/">Sign Out</a>)</small>
                <hr />
            </div>
          );
    }

}